@import '../../styles/variables';

.ecom-footer-nav {
  padding: calc(var(--spacing-unit) * 2) 0;
  &.specials-tray-padding {
    padding-bottom: 70px;
  }
  .logo {
    text-align: center;
    > svg > path {
      fill: var(--white);
    }
  }
  .services-buttons {
    margin-top: calc(var(--spacing-unit) * 2);
    gap: 60px;
  }
  .footer-links {
    padding: 0 100px;
    margin-top: calc(var(--spacing-unit) * 2);
    .curaleaf-accordion:first-of-type {
      border-top: 1px solid var(--white);
    }
    .curaleaf-accordion .footer-link-list {
      margin: 0;
      list-style: none;
      padding-left: 0;
      li:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }
  }
  .footer-disclaimer {
    margin: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2) 0
      calc(var(--spacing-unit) * 2);
    > div {
      max-width: 734px;
      margin: 0 auto;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .corp-social-media {
    max-width: 340px;
    margin: calc(var(--spacing-unit) * 2) auto;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 48px;
    .services-buttons {
      margin-top: var(--spacing-unit);
    }
    .footer-links {
      margin-top: var(--spacing-unit);
      padding: 0;
    }
    .footer-disclaimer {
      margin: calc(var(--spacing-unit) * 2) 24px 0 24px;
    }
  }
  @include media-breakpoint-down(xs) {
    .services-buttons {
      gap: 16px;
      justify-content: space-between;
      padding: 0 24px;
    }
  }
}

@include media-breakpoint-up(tablet) {
  .main-footer-nav-mobile {
    display: none;
  }
  .main-footer-nav-desktop {
    position: relative;
    .logo {
      border-bottom: 1px solid var(--white);
    }
    .services-link-group {
      gap: 60px;
    }
    .disclaimer {
      max-width: 427px;
      p {
        // override markdown p with body-sm
        font-family: var(--font-medium);
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 20px;
      }
      p:last-of-type {
        margin-bottom: 0;
      }
    }
    .footerlink-column {
      width: 214px;
    }
    .corp-social-media {
      z-index: 10;
      position: absolute;
      bottom: 40px;
      right: 40px;
    }
  }
}

@include media-breakpoint-down(tablet) {
  .main-footer-nav-desktop {
    display: none;
  }
}
