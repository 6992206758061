@import '../../styles/animations';
@import '../../styles/variables';

.specials-tray {
  position: fixed;
  z-index: 700;
  bottom: 0;
  min-width: 100vw;
  width: 100%;
  &.opened {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    @media (max-width: 374px) {
      min-width: 375px;
      min-height: 100%;
    }
  }
  .backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    animation: overlay-enter 150ms forwards;
  }
  .offers-button {
    cursor: pointer;
    z-index: 1;
    padding: 12px var(--spacing-unit);
    min-width: 300px;
    max-width: 600px;
    width: 82%;
    height: 50px;
    background-color: var(--primary);
    border: 1px solid var(--white);
    border-bottom: 1px solid var(--primary);
    color: var(--white);
    .arrow-up {
      transform: rotate(180deg);
    }
  }
  .tray-container {
    z-index: 1;
    pointer-events: auto;
    max-width: 1440px;
    background-color: var(--white);
    width: 100%;
    padding: 0 var(--spacing-unit);
    @include media-breakpoint-up(md) {
      padding: 0 calc(var(--spacing-unit) * 2);
    }
    max-height: 0;
    transition: max-height 0.5s ease-out;
    &.slide-up {
      max-height: 650px;
      transition: max-height 0.5s ease-in;
    }

    > section {
      margin-top: var(--spacing-unit);
    }
    .todays-offers-accordion {
      margin: var(--spacing-unit) auto 0;
      max-width: 600px;
      width: 100%;
      .p-accordion-header {
        svg {
          display: none;
        }
        .header {
          flex: 1;
          margin-right: 5px;
          .todays-offers-markdown {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            p {
              font-size: 12px;
              line-height: 16px;
              font-weight: unset;
              margin-bottom: unset;
              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .p-accordion-header-link {
        background-color: var(--primary);
        color: var(--white);
        padding: 3px var(--spacing-unit);
        height: 50px;
        border-radius: 0;
      }
      .p-accordion-content {
        background-color: var(--secondary-light);
        padding: var(--spacing-unit);
        height: 355px;
        overflow-x: auto;
        max-height: calc(100vh - 370px);
      }
    }
    .slider {
      padding-bottom: var(--spacing-unit);
    }
    .storefront-carousel {
      padding: 0;
    }
  }
}
