@import '../styles/variables';

.dispensary-path-layout {
  max-width: 1440px;
  margin: 0 auto;
  @include media-breakpoint-up(tablet) {
    .main {
      margin-left: 228px;
    }
  }
}
