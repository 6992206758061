@import '../../styles/variables';

.storefront-carousel {
  position: relative;
  .slider {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    scrollbar-width: none;
    @include media-breakpoint-down(sm) {
      gap: var(--spacing-unit);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      flex: 0 0 auto;
      scroll-snap-align: start;
      scroll-snap-stop: always;
      transition: ease 1000ms;
      max-width: 300px;
      width: calc(100% / var(--number-of-items));
      @include media-breakpoint-down(md) {
        --number-of-items: 3;
      }
      @include media-breakpoint-down(sm) {
        --number-of-items: 2;
      }
      &[data-type='categories'] {
        --number-of-items: 10;
        width: 110px;
        height: 110px;
      }
      &[data-type='subcategories'] {
        width: initial;
      }
      &[data-type='mobile-categories'] {
        --number-of-items: 6;
        width: 154px;
      }
      &[data-type='products'] {
        padding-left: 6px; // so strain tag doesn't get cut off
        --number-of-items: 5;
        width: 272px;
        @media (max-width: 1242px) {
          --number-of-items: 4;
        }
        @media (max-width: 939px) {
          --number-of-items: 3;
        }
        @include media-breakpoint-down(sm) {
          width: 164px;
          --number-of-items: 5;
        }
        @media (max-width: 733px) {
          --number-of-items: 4;
        }
        @media (max-width: 553px) {
          --number-of-items: 3;
        }
      }
      &[data-type='brands'] {
        --number-of-items: 9;
        width: 125px;
        height: 157px;
      }
      &[data-type='specials'] {
        --number-of-items: 4;
        min-width: 240px;
        height: 111px;
        @include media-breakpoint-down(lg) {
          --number-of-items: 3;
        }
        @include media-breakpoint-down(md) {
          --number-of-items: 2;
        }
        @include media-breakpoint-down(xs) {
          --number-of-items: 1;
        }
      }
    }
  }
  .next,
  .previous {
    z-index: 10;
    display: block;
    flex-grow: 0;
    width: 40px;
    height: 40px;
    padding: 6px;
    border: 2px solid var(--secondary-light);
    background-color: var(--secondary-light);
    border-radius: 8px;
    &:hover {
      opacity: 0.5;
    }
  }
  .next {
    right: 0;
  }
  .previous {
    left: 0;
  }
}

#categories {
  scroll-margin-top: calc(var(--spacing-unit) * 5);
  @include media-breakpoint-down(sm) {
    scroll-margin-top: calc(var(--spacing-unit) * 3);
  }
}
