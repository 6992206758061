@import '../../../styles/variables';

.marketing-intake-banner {
  gap: var(--spacing-unit);
  padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
  .fields-container {
    max-width: 560px;
    min-width: 320px;
    width: 100%;
    gap: var(--spacing-unit);
    section {
      min-width: unset !important;
      &:first-child {
        width: 70% !important;
        div > .text-input-curaleaf {
          .form-header-curaleaf {
            top: var(--spacing-unit);
            margin: 0;
          }
          input.p-filled {
            padding-top: var(--spacing-unit);
          }
          input:focus ~ label,
          input.p-filled ~ label {
            top: 0;
            font-size: 12px;
          }
        }
      }
      &:nth-child(2) {
        width: 30% !important;
      }
    }
    .ecom-button-curaleaf {
      padding-top: 2px;
    }
  }
}
