.header-drawer-sidebar {
  min-width: 315px;
  max-width: 495px;
  margin-right: 60px;
  width: 100%;
  padding-bottom: 154px;
  .p-sidebar-header {
    padding: 0;
    border-bottom: 1px solid var(--secondary-primary);
    .header {
      padding: 32px;
      width: 100%;
      .logo {
        svg > path {
          fill: var(--white);
        }
      }
      .drawer-close-icon {
        background-color: transparent;
        border: none;
        padding-right: 0;
        svg > path {
          stroke: var(--white);
        }
      }
    }
  }
  .drawer-links {
    padding: 16px 32px;
    list-style: none;
  }
  .p-sidebar-content {
    position: relative;
  }
}
