.strain-tag {
  position: absolute;
  left: -8px;
  top: 7px;
  z-index: 5;
  &.indica {
    > svg > path {
      fill: var(--indica);
    }
  }
  &.hybrid {
    > svg > path {
      fill: var(--hybrid);
    }
  }
  &.sativa {
    > svg > path {
      fill: var(--sativa);
    }
  }
  &.cbd {
    > svg > path {
      fill: var(--cbd);
    }
  }
}

.strain-name {
  position: absolute;
  left: 6px;
  top: 14px;
  z-index: 6;
}
