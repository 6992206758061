.special-tile-container {
  height: 100%;
  width: 100%;
  position: relative;
  border: 2px solid var(--secondary-primary);
  border-radius: 16px;
  gap: 10px;
  padding: 0 var(--spacing-unit);
  .special-icon {
    min-width: 36px;
    min-height: 36px;
    line-height: 0;
    > svg {
      transform: scale(1.2);
    }
  }
  .special-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 100%;
    min-height: calc(var(--spacing-unit) * 2);
  }
  &:hover {
    .special-title {
      color: var(--tertiary);
    }
  }
}
