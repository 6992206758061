@import '../../styles/variables';

.faq-block-curaleaf {
  margin-bottom: 100px;
  .heading p {
    width: 70%;
  }
  .accordion-wrapper {
    padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
  }
  .answer {
    max-width: 70%;
    overflow: hidden;
    a {
      text-decoration: underline;
    }
    ul {
      margin-bottom: var(--spacing-unit);
    }
    p:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: calc(var(--spacing-unit) * 2);
    .heading p {
      width: 100%;
    }
    .accordion-wrapper {
      padding: var(--spacing-unit) 0;
    }
  }
}
