.brand-tile-container {
  height: 100%;
  position: relative;
  z-index: 2;
  .tile {
    display: flex;
    height: 157px;
    width: 125px;
    img {
      border-radius: 16px;
      margin-bottom: 8px;
    }
  }
}
