@import '../../../styles/variables';

.hero-banner {
  position: relative;
  overflow: hidden;
  &.hero-image {
    height: 300px;
    max-height: 300px;
    .media-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &.hero-video {
    video {
      width: 100%;
      height: auto;
    }
  }
  .hero-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: RGB(0 0 0 / 30%);
      position: absolute;
      z-index: 2;
    }
  }
  .th-text-container {
    h1 {
      margin: 0;
      padding: 0;
    }
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .cta-button {
    margin-top: calc(var(--spacing-unit) * 2);
  }
  .th-text-placeholder {
    @extend .th-text-container;
    position: static;
    opacity: 0;
    z-index: -1;
  }
  @include media-breakpoint-down(sm) {
    &.hero-image {
      height: auto;
      max-height: unset;
      min-height: 200px;
    }
    &.text-below {
      .media-container {
        position: static;
      }
      .th-text-container {
        position: static;
      }
    }
    .th-text-container {
      height: 100%;
      padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
    }
  }
}
