@import '../../styles/variables';

.promo-banner-carousel {
  .carousel-wrapper {
    position: relative;
  }
  .promo-carousel-dots {
    width: 100%;
    .thumbs {
      margin-top: calc(var(--spacing-unit) * 2);
      display: flex;
      justify-content: center;
      text-align: center;
      .sm-dot {
        padding: 0;
        border: 1px solid #c9c9c9;
        width: 8px;
        height: 8px;
        background-color: #c9c9c9;
        border-color: #c9c9c9;
        border-radius: 100%;
        margin: 0 calc(var(--spacing-unit) / 3);
        &.active {
          border: 1px solid var(--primary);
          background-color: var(--primary);
          border-color: var(--primary);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
    .promo-carousel-dots {
      margin-top: var(--spacing-unit);
      position: absolute;
      bottom: var(--spacing-unit);
    }
  }
}
