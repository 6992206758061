@import '../../../../styles/variables';

.subcategory-button {
  padding: 8px 24px;
  height: 44px;
  &[data-selected='true'] {
    border: 2px solid var(--primary);
  }
  &[data-selected='false'] {
    border: 2px solid var(--input-normal);
  }
  @include media-breakpoint-down(tablet) {
    height: 56px;
  }
  @include media-breakpoint-down(sm) {
    height: 44px;
  }
}
