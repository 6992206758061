@import '../../../styles/variables';

.promo-banner-curaleaf {
  width: 100%;
  .column {
    height: 100%;
    flex-direction: column-reverse;
  }
  .text-block-section {
    text-align: left;
    padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
    p {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .promo-image-banner {
    width: 100%;
    position: relative;
  }
  @include media-breakpoint-up(md) {
    .column {
      &.lg-row {
        margin-left: calc(var(--spacing-unit) * 2);
        flex-direction: row;
        .thumbs {
          text-align: left;
        }
        .promo-image-banner-wrapper {
          margin-left: -5%;
          margin-right: -5%;
        }
      }
      &.reverse {
        margin-right: calc(var(--spacing-unit) * 2);
        flex-direction: row-reverse;
        .promo-image-banner-wrapper {
          margin-left: -5%;
          margin-right: -5%;
        }
      }
    }
    .text-block-section {
      z-index: 1;
      position: relative;
      padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 2);
      text-align: center;
      border-radius: 10px;
      margin: 0;
    }
    .promo-image-banner {
      position: relative;
    }
  }
  @include media-breakpoint-up(lg) {
    .text-block-section {
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 2);
    }
  }
  @include media-breakpoint-down(sm) {
    height: 100%;
    .column {
      height: 100%;
    }
    .text-block-section {
      width: 100%;
      height: 100%;
      padding-bottom: 45px;
    }
  }
}
