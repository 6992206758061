@import '../../styles/variables';

.product-popup {
  z-index: 201;
  max-width: 372px;
  width: 100%;
  position: absolute;
  right: 6px;
  box-shadow: 0 3px 20px #00000029;
  pointer-events: auto;
  border-radius: 4px;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    top: 0;
    right: 0;
  }
  .tail-container > .popup-tail {
    box-shadow: 7px 2px 10px -4px rgba(0, 0, 0, 0.3);
    height: var(--spacing-unit);
    width: var(--spacing-unit);
    position: absolute;
    top: -8px;
    right: 32px;
    transform: rotateX(45deg) rotateZ(-45deg);
    @include media-breakpoint-down(sm) {
      right: 26px;
    }
  }
  .title {
    padding: var(--spacing-unit);
    border-bottom: 1px solid var(--light-gray);
  }
  .product-list-tile {
    min-height: fit-content;
    box-shadow: unset;
    .info-container > .inbv-info > .nbv {
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .price-info {
        text-align: left;
      }
    }
  }
  .cart-btn {
    border-top: 1px solid var(--light-gray);
    padding: var(--spacing-unit);
  }
}
