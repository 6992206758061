@import '../../styles/variables';

.dispensary-overview {
  padding-bottom: calc(var(--spacing-unit) * 2);
  .store-information {
    max-height: 700px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;
    .text-container,
    .image-container {
      width: 50%;
    }
    .text-container > .store-details {
      height: 100%;
      margin: auto;
      gap: var(--spacing-unit);
      .store-info,
      .store-info > a,
      .store-hours > .hours-container,
      .store-hours > .hours-container > .hours {
        gap: 10px;
      }
      .store-hours {
        border-radius: 16px;
        border: 1px solid var(--tertiary-light);
      }
      .store-info > a > span {
        width: fit-content;
      }
      .split-menu-buttons {
        gap: 5px;
        .cta-button {
          width: 100%;
        }
      }
    }
    .text-container[data-no-images='false'] {
      width: 100%;
    }
    .image-container[data-no-images='false'] {
      width: 0;
    }
    .image-container > .overview-image-carousel {
      position: relative;
      height: 100%;
      .carousel {
        height: 100%;
      }
      .carousel-details {
        height: 100%;
        .carousel-el {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
          img {
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .thumbs {
        width: 100%;
        position: absolute;
        bottom: 2%;
        > section {
          text-align: center;
        }
        .sm-dot {
          padding: 0;
          border: 1px solid var(--secondary-primary);
          width: 8px;
          height: 8px;
          background-color: var(--secondary-primary);
          border-color: var(--secondary-primary);
          border-radius: 100%;
          margin: 0 calc(var(--spacing-unit) / 3);
          &.active {
            border: 1px solid var(--primary);
            background-color: var(--primary);
            border-color: var(--primary);
          }
        }
      }
    }
  }
  .store-overview {
    gap: calc(var(--spacing-unit) * 2);
    .store-offers {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
      gap: var(--spacing-unit);
    }
  }
  @include media-breakpoint-down(md) {
    .store-information > .text-container > .store-details {
      .split-menu-buttons {
        flex-direction: column;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: var(--spacing-unit);
    .store-information {
      max-height: unset;
      flex-direction: column-reverse;
      padding: 0;
      .text-container,
      .text-container > .store-details,
      .image-container {
        width: 100%;
      }
      .image-container {
        height: 400px;
        .thumbs > .dots-parent > .sm-dot {
          height: var(--spacing-unit) !important;
          width: var(--spacing-unit) !important;
        }
      }
      @include media-breakpoint-down(xs) {
        .text-container {
          padding: var(--spacing-unit);
        }
        .image-container {
          height: 245px;
        }
      }
    }
    .store-overview {
      gap: var(--spacing-unit);
      padding: var(--spacing-unit) 0;
    }
  }
}
