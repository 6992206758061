@import '../../styles/variables';

.prev-btn {
  border: 0;
  border-radius: 50%;
  background: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: 0.3s ease-in-out;
  width: calc(var(--spacing-unit) * 2);
  height: calc(var(--spacing-unit) * 2);
  color: var(--primary);
  padding: 0;
}

.next-btn {
  border: 0;
  border-radius: 50%;
  background: none;
  cursor: pointer;
  outline: none;
  transition: 0.3s ease-in-out;
  width: calc(var(--spacing-unit) * 2);
  height: calc(var(--spacing-unit) * 2);
  margin-top: 1px;
  color: var(--primary);
  padding: 0;
}

.prev-btn,
.next-btn {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.carousel-thumbnails {
  .thumbnail {
    &:not(:first-child) {
      margin-top: 24px;
    }
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 16px;
    border: 2px solid rgba(89, 131, 141, 0.1);
    position: relative;
  }
}
