@import '../../styles/animations';
@import '../../styles/variables';

@mixin navigation-popup() {
  overflow-y: scroll;
  max-width: 586px;
  width: 100%;
  z-index: 500;
  position: fixed;
  inset: 0 30% 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--spacing-unit) * 4);
  background-color: var(--white);
  padding: 27px 35px;
  transform: translateX(-100%);
  transition: transform 350ms ease-out;
  .header-links {
    .header-item {
      white-space: nowrap;
      &[data-width='false'],
      [data-width]:not([data-width]) {
        display: unset !important;
      }
    }
    .more-link {
      display: none;
    }
  }
  .popup-header {
    width: 100%;
    min-height: 42px;
    .close-icon,
    .selected-store {
      display: block;
      padding: 0;
      &:hover {
        svg > path {
          fill: var(--input-hover);
        }
      }
    }
    .selected-store {
      background-color: transparent;
      border: 1px solid var(--primary);
      border-radius: 100px;
      padding-inline: 8px;
      &:hover {
        border: 1px solid var(--input-hover);
        span {
          color: var(--input-hover);
        }
      }
    }
    svg > path {
      fill: var(--primary);
    }
  }
  .primary-navigation {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    list-style: none;
    li {
      width: 100%;
      a:hover,
      p:hover,
      span:hover {
        color: var(--input-hover);
        text-decoration: underline;
      }
    }
  }
  .social-user {
    width: 100%;
    display: flex;
    .extra-links {
      margin-top: 24px;
      gap: 8px;
      border-bottom: 1px solid var(--secondary-primary);
      padding-bottom: calc(var(--spacing-unit) * 2);
    }
    .user-navigation > .user-container {
      padding-top: calc(var(--spacing-unit) * 2);
      .user-links {
        gap: 8px;
        list-style: none;
        padding: 20px 0;
        margin: unset;
      }
    }
    .social-icons-curaleaf {
      width: fit-content;
      padding-top: calc(var(--spacing-unit) * 2);
      > div {
        gap: 8px;
      }
      .s-comp {
        gap: 13px;
        .s-icon {
          width: 24px;
          padding: 0;
        }
      }
    }
  }
}

@mixin search-components {
  position: relative;
  width: 100%;
  height: 52px;
  padding-inline: 12px;
  gap: 12px;
  .search-input {
    border: none;
    width: 100%;
    text-overflow: ellipsis;
    &:placeholder {
      color: var(--secondary-primary);
    }
    svg {
      position: absolute;
      margin-left: 12px;
    }
    &:-webkit-autofill {
      box-shadow: 0 0 0 calc(var(--spacing-unit) * 2) var(--secondary-primary)
        inset;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

.header-dispensary-popup,
.header-user-popup,
.product-popup-container {
  position: relative;
}
.c-header {
  padding: 12px calc(var(--spacing-unit) * 2);
  gap: calc(var(--spacing-unit) * 2);
  width: 100%;
  .nav-container {
    width: 100%;
    gap: calc(var(--spacing-unit) * 4);
    overflow: hidden;
  }
  .navigation {
    width: 100%;
    overflow: hidden;
    .close-icon,
    .selected-store,
    .social-user {
      display: none;
    }
    .scrollbar-navigation {
      gap: calc(var(--spacing-unit) * 2);
      height: 50px;
      list-style: none;
      .header-item {
        span[data-popup-link='true'] {
          &:hover {
            color: var(--input-hover);
            text-decoration: underline;
          }
          svg {
            display: none;
          }
        }
        &.more-link:hover {
          color: var(--input-hover);
          text-decoration: underline;
        }
      }
    }
  }
  &[data-no-store='true'] {
    width: 100%;
    gap: calc(var(--spacing-unit) * 2);
    .navigation > .header-links {
      justify-content: center;
    }
  }
  .search-user-cart {
    height: 52px;
    width: 50%;
    .search {
      @include search-components;
    }
    .user-cart {
      position: relative;
      gap: var(--spacing-unit);
      .pointer > div > svg:hover {
        path {
          fill: var(--input-hover);
        }
      }
      .icon-link {
        position: relative;
      }
      .icon-link:hover {
        color: var(--white);
        text-decoration: none;
        svg > path {
          fill: var(--input-hover);
        }
        .quantity {
          background-color: var(--input-hover);
        }
      }
      .quantity {
        position: absolute;
        left: 18px;
        bottom: 22px;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        color: var(--white);
        text-decoration: none;
      }
    }
    &[data-no-store='true'] {
      width: 32px;
    }
  }
}
.nav-sublink-popup {
  @include navigation-popup;
  .header-links > .header-item {
    display: unset !important;
  }
  &[data-visible='true'] {
    transform: translateX(0);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  }
}

@mixin mobile-quantity {
  position: absolute;
  left: 11px;
  bottom: 17px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

@include media-breakpoint-down(tablet) {
  .c-header {
    flex-direction: column;
    gap: 10px;
    &[data-search-variant='true'] {
      height: 57px;
      padding: unset;
      .search {
        margin: unset;
        height: 100%;
        padding: 12px calc(var(--spacing-unit) * 2);
        gap: 12px;
        padding: var(--spacing-unit);
        animation: openSearch 0.5s ease-out;
      }
    }
    .nav-container {
      max-width: 1024px;
      width: 100%;
      .nav-header {
        width: 100%;
        .hamburger-search {
          gap: 12px;
          width: calc(100% / 3);
          .mobile-nav-toggle {
            width: unset;
          }
        }
        .mobile-nav-toggle {
          width: calc(100% / 3);
          height: 24px;
        }
        .nav-logo {
          width: calc(100% / 3);
          height: 100%;
        }
        .user-cart {
          width: calc(100% / 3);
          gap: 10px;
          position: relative;
          padding-top: 8px;
          padding-right: 7px;
          .icon-link {
            position: relative;
          }
          .quantity {
            @include mobile-quantity;
          }
          &[data-has-hamburger='true'] {
            padding-right: unset;
            width: unset;
          }
        }
      }
      .navigation {
        @include navigation-popup;
        max-width: 420px;
        gap: 24px;
        padding: 12px var(--spacing-unit);
        .header-links > .more-link {
          display: none !important;
        }
        .mobile-accordion-content {
          list-style: none;
          padding-left: var(--spacing-unit);
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .sublinks {
          padding-bottom: calc(var(--spacing-unit) * 4);
        }
        &[data-visible='true'] {
          transform: translateX(0%);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
        }
      }
    }
    .search {
      @include search-components;
      min-width: 100%;
      margin: 12px;
    }
  }
  .nav-sublink-popup[data-visible='true'] {
    transform: translateX(-100%);
  }
}

@include media-breakpoint-down(sm) {
  .c-header {
    padding: 10px var(--spacing-unit);
  }
}

@mixin popup {
  max-width: 250px;
  width: 100%;
  position: absolute;
  right: 0;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: var(--spacing-unit) 12px;
  gap: var(--spacing-unit);
  .tail-container {
    position: relative;
    .popup-tail {
      box-shadow: 7px 2px 10px -4px rgba(0, 0, 0, 0.3);
      height: var(--spacing-unit);
      width: var(--spacing-unit);
      position: absolute;
      bottom: 8px;
      right: 75px;
      transform: rotateX(45deg) rotateZ(-45deg);
    }
  }
  @include media-breakpoint-down(tablet) {
    max-width: 480px;
    margin: 0 auto;
    position: fixed;
    inset: 65% 0 0;
    background-color: var(--white);
    padding-inline: 20px;
    transform: translateY(100%);
    transition: transform 350ms ease-out;
    &[data-visible='true'] {
      transform: translateY(0);
    }
  }
}

.header-user-popup {
  position: relative;
  z-index: 901; // needs to be infront of the specials tray on mobile
  .popup-container {
    @include popup;
    &[data-no-cart-icon='true'] {
      .tail-container > .popup-tail {
        right: 27px;
      }
    }
  }
}
.header-dispensary-popup {
  @extend .header-user-popup;
  .popup-container {
    left: 0;
    .tail-container > .popup-tail {
      left: 21px;
    }
  }
}

[data-tooltip-backdrop='true'] {
  background-color: transparent !important;
}

.kiosk-header {
  height: 68px;
  padding: 10px calc(var(--spacing-unit) * 2);
  .kiosk-search {
    @include search-components;
    min-width: 100%;
    height: 48px;
  }
  .navigation {
    width: 100%;
    justify-content: space-between;
    .kiosk-header-links {
      list-style: none;
    }
  }
  .icon-link {
    margin-top: 5px;
  }
  .user-cart {
    position: relative;
  }
  .quantity {
    @include mobile-quantity;
  }
}
