@import '../../styles/variables';

.careers {
  width: 80%;
  margin: calc(var(--spacing-unit) * 2) auto;
  .career-header {
    text-align: center;
    .career-legal {
      text-decoration: underline;
      &:hover {
        color: var(--input-normal);
      }
    }
  }
  .search {
    display: flex;
    align-items: baseline;
    padding: 0px;
    font-family: var(--font);
    margin: 10px auto;
    max-width: 650px;
    min-width: 320px;
    flex-direction: column;
    > label {
      margin-right: 10px;
    }
    .search-job-listing-input {
      border-radius: 6px;
      border: 1px solid var(--input-normal);
      width: 100%;
      padding: 0 var(--spacing-unit);
      margin-right: var(--spacing-unit);
      white-space: nowrap;
      min-height: 52px;
      font-size: 16px;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
    }
  }
  .all-listing {
    border: none;
    background: transparent;
    > ul > li {
      width: 100%;
      .p-treenode-content {
        box-shadow: none !important;
        border-bottom: 1px solid var(--secondary-primary);
        border-radius: 0;
        button:focus {
          box-shadow: none !important;
        }
      }
    }
    .career-state {
      font-family: var(--font-bold);
      font-size: 25px;
      color: var(--primary);
      .career-legal {
        text-decoration: underline;
        &:hover {
          color: var(--input-normal);
        }
      }
    }
    .career-listing {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: end;
      margin-right: 2rem;
      .career-list {
        display: flex;
        flex-direction: column;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: var(--spacing-unit);
    .all-listing {
      padding: 0;
      > ul > li > ul {
        padding: 0;
      }
      .career-listing {
        display: unset;
        text-align: left;
        .career-list {
          margin-left: 1rem;
        }
      }
    }
  }
}
