@import '../../../styles/variables';

.small-banner {
  width: 100%;
  .column {
    border-radius: 0;
    width: 100%;
    height: 18rem;
    flex-direction: column-reverse;
    justify-content: center;
    @include media-breakpoint-up(md) {
      border-radius: 10px;
      &.row {
        justify-content: space-between;
        flex-direction: row;
      }
      &.reverse {
        justify-content: flex-end;
        flex-direction: row-reverse;
      }
    }
  }
  .cta-button {
    margin-top: var(--spacing-unit);
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      width: 100%;
      .btn-primary {
        width: 100%;
        text-align: center;
      }
    }
  }
  .text-container {
    text-align: center;
    padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
    color: var(--white);
    @include media-breakpoint-up(md) {
      height: 18rem;
      text-align: left;
      > div {
        h5,
        p:last-child {
          margin: 0;
        }
        h5 {
          padding-bottom: 0;
        }
      }
    }
  }

  .small-image-banner {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .reverse {
    .small-image-banner {
      @include media-breakpoint-up(md) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }

  .row {
    .small-image-banner {
      @include media-breakpoint-up(md) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .subtitle {
    font-family: var(--font);
    padding: var(--spacing-unit) 0 calc(var(--spacing-unit) * 2);
    margin-bottom: 0;
    height: fit-content;
    color: var(--primary);
  }
  .text-right {
    @include media-breakpoint-down(md) {
      text-align: right;
    }
  }
}
