@import '../../styles/variables';

.main-header-nav,
.mobile-main-header-nav {
  min-width: 375px;
  top: 0;
  z-index: 701;
}

@include media-breakpoint-up(tablet) {
  .main-header-nav {
    position: fixed;
    width: 100%;
    padding: 20px calc(var(--spacing-unit) * 2);
    height: 179px;
    background-color: transparent;
    transition: all 0.3s ease;
    gap: 20px;
    .cta-button-curaleaf .cta-button-secondary {
      border: 2px solid var(--white);
      height: 48px;
      padding: 10px 20px;
    }
    .logo-container {
      height: 71px;
    }
    &[data-scrolled='true'] {
      transition: all 0.3s ease;
      background-color: var(--primary);
    }
    .header-logo {
      height: 33px;
      display: inline-block;
      svg > path {
        fill: var(--tertiary-primary);
      }
    }
    .nav-links {
      max-width: 865px;
      margin: 0 auto;
      list-style: none;
      li {
        line-height: unset;
      }
    }
  }
  .mobile-main-header-nav {
    display: none;
  }
}

@include media-breakpoint-down(tablet) {
  .main-header-nav {
    display: none;
  }
  .mobile-main-header-nav {
    position: sticky;
    height: 64px;
    background-color: var(--primary);
    .hamburger-icon {
      height: 24px;
    }
    .logo-icon {
      height: 40px;
      margin-left: 94px;
    }
  }
}
