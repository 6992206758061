.quantity-select {
  position: relative;
  height: 24px;

  input {
    position: relative;
    min-width: 24px;
    border: none;
    text-align: center;
    -webkit-appearance: textfield;
    appearance: textfield;
    transition: none;
    padding: 0;
  } /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    &:focus {
      outline: none;
    }
  }
  &.pdp-type {
    height: 56px;
    width: 123px;
    background-color: var(--secondary-light);
    border-radius: 16px;
    padding: 8px;
    input {
      background-color: var(--secondary-light);
    }
  }
  .quantity-btn {
    background-color: var(--secondary-light);
    padding: 0;
    width: 24px;
    height: 24px;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    &:first-of-type {
      order: -1;
    }
    .visually-hidden,
    .visually-hidden-focusable:not(:focus):not(:focus-within) {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
    &.disabled {
      cursor: default;
      > svg > path {
        fill: var(--secondary-dark);
      }
    }
    &:hover:not(.disabled) {
      > svg > path {
        fill: var(--input-hover) !important; //override inline svg styles
      }
    }
  }
}
