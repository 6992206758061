@import '../../../styles/variables';

.category-menu {
  display: none;
  @include media-breakpoint-up(tablet) {
    display: revert;
    z-index: 4;
    ul {
      list-style: none;
      padding: 24px calc(var(--spacing-unit) * 2);
      margin: 0;
      .cat-link {
        .category-icon {
          height: 24px;
          width: 24px;
        }
      }
    }
    position: absolute;
    left: 0;
    top: 0;
    width: 228px;
    height: 100%;
    background-color: var(--white);
    border-right: 1px solid var(--secondary-primary);
    > div {
      position: sticky;
      top: 76px;
      overflow-y: auto;
    }
  }
}
