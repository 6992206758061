.category-tile-container {
  height: 100%;
  position: relative;
  z-index: 2;
  .tile {
    display: flex;
    height: 110px;
    width: 110px;
    border-radius: 8px;
    border: 1px solid var(--secondary-primary);
    .image {
      padding: 12px 12px 5px;
    }
    &:hover {
      text-decoration: none;
      h5 {
        color: var(--input-normal);
      }
      .icon > svg {
        transform: scale(1.1);
        circle,
        rect {
          fill: var(--input-normal) !important;
        }
      }
    }
  }
}
