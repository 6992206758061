@import '../../styles/variables';

@mixin mobile-styles {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  .inbv-info {
    flex: 0 0 auto;
  }
  .pv-info {
    width: 100%;
    align-items: flex-end;
  }
}

.product-list-tile {
  position: relative;
  min-height: 132px;
  border: 2px solid var(--secondary-primary);
  border-radius: 16px;
  &.popup {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
  .spinner-icon {
    background-color: transparent;
    width: 100%;
    .loading-spinner {
      border-color: var(--primary);
      border-right-color: transparent;
    }
  }
  .info-container {
    width: 100%;
    gap: calc(var(--spacing-unit) * 2);
    .inbv-info {
      gap: calc(var(--spacing-unit) * 2);
      .image-container {
        &:hover {
          transform: scale(1.05);
        }
        > a {
          &:hover {
            opacity: 1;
          }
        }
        min-width: 100px;
        position: relative;
        height: 100px;
        width: 100px;
      }
    }
    .right-col {
      gap: calc(var(--spacing-unit) * 2);
    }
    .cart-price-info {
      min-width: 63px;
      .was-price,
      .save-price {
        text-wrap: nowrap;
      }
    }
    .pv-info {
      position: relative;
      .variant-button {
        min-width: 88px;
        max-width: 105px;
        width: 100%;
        padding: var(--spacing-unit);
        border-radius: 4px;
        border: 1px solid var(--secondary-primary);
        &[data-has-variant='true'] {
          gap: 12px;
        }
      }
      .quantity-tag {
        z-index: 1;
        position: absolute;
        width: 28px;
        height: 28px;
        top: -8px;
        right: -8px;
        text-align: center;
        border-radius: 50%;
        background-color: var(--white);
        border: 1px solid var(--secondary-primary);
        padding-top: 3px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .info-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      .inbv-info {
        width: 100%;
        align-items: flex-start;
        gap: var(--spacing-unit);
        .nbv {
          flex-grow: 1;
          justify-content: flex-start;
          .product-brand {
            order: 1;
          }
          .product-name {
            order: 2;
          }
          .qd {
            order: 5;
            align-items: flex-start;
          }
          .cart-price-info {
            order: 3;
            margin: 4px 0;
            text-align: left;
          }
          .moodi-day-rating-overlay {
            order: 4;
          }
          .card-description {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            order: 6;
            .trash-btn {
              padding: 0;
              background-color: transparent;
              border: 0;
              height: 24px;
            }
          }
        }
      }
      .right-col {
        gap: 0;
        flex-direction: column-reverse;
        margin-left: 116px;
        align-items: flex-start;
      }
      .cart-price-info {
        .was-price,
        .save-price {
          margin-bottom: 3px;
        }
      }
      .cart-price-section {
        text-align: left;
        flex-direction: row;
        align-items: flex-end;
        column-gap: 8px;
        row-gap: 0;
        flex-wrap: wrap;
      }
    }
  }
  [data-order-history='true'] {
    @include mobile-styles;
  }
}
