@import '../../styles/variables';

.license-bar-curaleaf {
  background: var(--secondary-light);
  width: 100%;
  color: var(--primary);
  text-align: center;
  padding: 10px;
  p {
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
