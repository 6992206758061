@import '../../../styles/variables';

.instruction-steps {
  list-style: none;
  counter-reset: item;
  padding: 0;
  li {
    position: relative;
    margin-bottom: calc(var(--spacing-unit) * 2);
    span {
      display: inline-block;
      position: relative;
      left: calc(var(--spacing-unit) * 3);
      width: calc(100% - 45px);
    }
    &:before {
      position: absolute;
      content: counter(item);
      counter-increment: item;
      display: inline-block;
      width: 35px;
      height: 35px;
      text-align: center;
      border-radius: 50%;
      background-color: var(--black);
      color: var(--white);
      line-height: 35px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-right: 10px;
  }
}
