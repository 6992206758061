.inactivity {
  font-family: var(--font);
  width: 335px;
  .p-dialog-header {
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--primary);
    .p-dialog-header-icon {
      &:hover {
        color: var(--primary) !important;
        border-color: transparent;
        background: var(--cool-gray) !important;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .p-dialog-content {
    display: flex;
    align-items: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-bottom: 1.5rem;
    > section > div {
      margin-top: 0;
      > div > button {
        padding: 5px 10px !important;
      }
    }
    .inactivity-timer {
      display: flex;
      position: relative;
      margin-left: var(--spacing-unit);
      .p-progress-spinner,
      .inactivity-time {
        width: 50px;
        height: 50px;
      }
      .inactivity-time {
        font-family: var(--font);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    section > div > .cta-button > .btn {
      width: 100%;
    }
  }
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--primary);
  }
}
