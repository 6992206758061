@import '../../styles/variables';

.feedback-tab {
  width: 100px;
  position: fixed;
  bottom: 12rem;
  right: -2.3rem;
  z-index: 102;
  transform: rotate(90deg);
  @include media-breakpoint-down(sm) {
    padding: 2px;
    right: -38px;
  }
}

.p-dialog-mask > .feedback-dialog {
  max-width: 800px;
  width: 100%;
  position: absolute;
  .p-dialog-header {
    .p-dialog-header-icons .p-dialog-header-icon {
      span {
        color: var(--primary);
      }
      &:hover {
        color: var(--primary) !important;
        border-color: transparent;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .p-dialog-content {
    overflow: hidden;
    height: 800px;
    .feedback-iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
}
