@import '../../styles/variables.scss';

.MultiTextBlock {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary);
  }
  .column-wrapper {
    column-gap: calc(var(--spacing-unit) * 2);
    row-gap: calc(var(--spacing-unit) * 2);
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .column {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--primary);
    }
    p:last-child {
      margin-bottom: 0;
    }
    a {
      text-decoration: underline;
    }
    ul {
      margin-bottom: var(--spacing-unit);
    }
    flex: 1 1 0px;
    flex-basis: 0;
  }
  .cta-button-group,
  .stores-group {
    flex-wrap: wrap;
    gap: var(--spacing-unit);
  }
  @include media-breakpoint-up(md) {
    .cta-button-group > section {
      width: unset !important;
    }
    .stores-group {
      section {
        width: 420px !important;
      }
      &[data-store-align='center'] {
        justify-content: center;
      }
    }
    .column-wrapper {
      flex-wrap: nowrap;
    }
    .column {
      width: unset;
      padding: 0;
      &.full-width img {
        width: 100%;
        height: auto;
      }
    }
  }
}
