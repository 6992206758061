@import '../../styles/variables.scss';

.instagram-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }
  .insta-image {
    position: relative;
    &:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }
    .link {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      opacity: 0;
      transition: 0.2s;
      cursor: pointer;
      z-index: 2;
      height: 36px;
      width: 36px;
      svg {
        height: 36px;
        width: 36px;
      }
      svg > path {
        fill: var(--white);
      }
    }
    &:hover {
      &:after {
        opacity: 1;
      }
      .link {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
