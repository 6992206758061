@import '../../styles/variables';

.herobanner {
  position: relative;
  min-height: 700px;
  .image {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .content {
    max-width: 897px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content-placeholder {
    visibility: hidden;
    z-index: -1;
  }
  @include media-breakpoint-down(sm) {
    min-height: 400px;
  }
}
