@import '../../styles/variables';

.product-card {
  scroll-margin-top: 75px;
  background-color: var(--gray);
  padding: 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  .description-section {
    .product-image {
      margin: auto;
      width: 40%;
      min-width: 160px;
    }
    .title-and-description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--primary);
      }
      h5 {
        margin-bottom: 5px;
        font-family: var(--font-bold);
        line-height: normal;
      }
    }
    .feature-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--spacing-unit);
      .feature-header {
        font-family: var(--font-bold);
        margin-bottom: unset;
        padding-bottom: unset;
        font-size: clamp(
          1.125rem,
          calc(1.125rem + ((1vw - 0.234375rem) * 0.7767)),
          1.875rem
        );
      }
      ul {
        margin: 0;
        padding: 0 0 0 20px;
        li {
          font-family: var(--font);
        }
      }
    }
    .cta-button {
      margin-top: var(--spacing-unit);
    }
  }
  @include media-breakpoint-up(md) {
    display: table;
    .description-section {
      display: table-row;
      .product-image {
        width: 35%;
        display: table-cell;
        position: relative;
        img {
          position: absolute;
          height: 100%;
          width: auto;
        }
      }
      .title-and-description {
        display: table-cell;
        width: 65%;
        padding: 50px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .description-section {
      .feature-section {
        grid-template-columns: 1fr;
      }
      .product-image {
        width: 100%;
        > span {
          position: relative !important;
          min-height: 500px;
        }
        img {
          object-fit: contain;
        }
      }
    }
  }
  &.anchor {
    scroll-margin-top: 125px;
  }
  &.primary {
    background-color: var(--gray);
    .description-section {
      .title-and-description {
        .product-name {
          color: var(--primary);
        }
      }
      .feature-section {
        .feature-header {
          color: var(--primary);
        }
      }
    }
  }
}

#relieve,
#balance,
#boost,
#slumber,
#soothe {
  margin: 45px 0;
}
