@import '../../../styles/animations';
@import '../../../styles/variables';

.loader {
  background-color: var(--secondary-dark);
  opacity: 0.1;
  border-radius: 4px / 6.7px;
  animation: 2s ease-in-out 1s infinite normal none running fadeInOut;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
  &:empty::before {
    content: '.';
    color: transparent;
  }
  &.bd-img {
    height: 150px;
    width: 150px;
    @include media-breakpoint-up(md) {
      height: 100%;
      width: 100%;
    }
  }
  &.tile-img {
    width: 100%;
    object-fit: contain;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
  &.round-img {
    border: 1px solid var(--secondary-dark);
    border-radius: 100%;
    overflow: hidden;
  }
}
