.sublink-dropdown {
  &.p-menu {
    width: auto;
    padding: var(--spacing-unit) 6px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .p-submenu-header {
      display: none;
    }
    .p-menuitem-link {
      padding: 10px var(--spacing-unit);
      border-radius: 6px;
    }
    .p-menuitem-text {
      font-family: var(--font-medium);
      color: var(--primary);
      text-wrap: nowrap;
    }
    .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover,
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
      > .p-menuitem-content {
      color: var(--primary);
      background: var(--tertiary-light);
      border-radius: 6px;
      .p-menuitem-link {
        opacity: 1;
      }
      .p-menuitem-text {
        font-family: var(--font-bold);
      }
    }
  }
}
