@import '../../styles/variables';

.product-tile-container {
  display: block;
  border: 2px solid var(--secondary-primary);
  border-radius: 16px;
  position: relative;
  z-index: 2;
  &.view-grid {
    height: 100%;
    padding-bottom: 56px;
    width: 272px;
    .cart-button-wrapper {
      position: absolute;
      bottom: var(--spacing-unit);
      width: calc(100% - 32px);
      > button {
        width: 100%;
      }
    }
    .product-details {
      margin-top: var(--spacing-unit);
      padding-bottom: 24px;
    }
    .product-additional-info {
      margin-top: 10px;
    }
    .product-name {
      height: 56px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    @include media-breakpoint-down(sm) {
      width: 164px;
      .product-name {
        height: 40px;
      }
      .product-details {
        padding-bottom: var(--spacing-unit);
      }
    }
  }
  > a {
    display: inline-block;
  }
  .tile {
    display: inline-block;
    height: 100%;
    width: 100%;
    .product-details {
      display: block;
      h5,
      div {
        text-decoration: none;
      }
    }
  }
  .image {
    position: relative;
    border-radius: 16px;
    display: block;
    height: 236px;
    width: 100%;
    overflow: hidden;
    .product-img {
      position: static !important; //overwrite next js style
      border-radius: 16px;
      width: 100%;
      object-fit: contain;
    }
    @include media-breakpoint-down(sm) {
      height: 128px;
    }
  }
  .price {
    display: flex;
    align-items: center;
    .old-price {
      text-decoration: line-through;
      display: inline-block;
    }
  }
  .product-options {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    height: 30px;
    .option-tag {
      display: inline-block;
      padding: 3px 8px;
      border-radius: 4px;
      &.selected {
        background-color: var(--tertiary-primary);
      }
    }
    .variant-dropdown {
      width: 100%;
    }
    .variant-dropdown .p-dropdown {
      width: 100%;
      height: 30px;
      background-color: var(--secondary-light);
      border-radius: 4px;
      border-color: var(--secondary-light);
      .p-inputtext {
        padding: 3px 8px;
        font-size: 12px;
        font-family: var(--font-medium);
        color: var(--primary);
      }
      .p-dropdown-item-label {
        font-size: 12px;
      }
      .arrow-up {
        height: 24px;
        transform: rotate(180deg);
      }
      .arrow {
        height: 24px;
      }
    }
    .single-variant {
      padding: 2px 0;
    }
  }
  &.view-list {
    .tile {
      padding-bottom: var(--spacing-unit);
      display: flex;
      gap: 32px;
      align-items: center;
    }
    .image {
      height: 120px;
      position: relative;
      display: block;
      width: 120px;
    }
    .left-col {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }
    .product-details {
      width: calc(100% - 120px - 32px);
    }
    .product-options {
      height: 56px;
      width: 150px;
      .single-variant,
      .variant-dropdown .p-dropdown {
        height: 56px;
        width: 100%;
        padding: var(--spacing-unit) 12px;
        background-color: var(--secondary-light);
        border-radius: 12px;
        font-size: var(--spacing-unit);
        border-color: var(--secondary-light);
        .p-inputtext {
          padding: 0;
          font-size: 16px;
          font-family: var(--font-medium);
        }
        &:not(.p-disabled).p-focus {
          box-shadow: var(--outline);
        }
      }
    }
    .cart-button-wrapper {
      width: 193px;
      button {
        width: 100%;
      }
    }
    .product-additional-info {
      margin-top: 4px;
    }
    .right-col,
    .left-col {
      gap: 24px;
    }
    .right-col {
      justify-content: flex-end;
    }
    @media (max-width: 1200px) {
      .left-col,
      .right-col {
        width: 100%;
      }
      .product-details {
        flex-grow: 1;
      }
      .tile {
        gap: var(--spacing-unit);
        flex-direction: column;
        align-items: flex-start;
      }
      .cart-button-wrapper {
        flex: 1;
      }
    }
    @media (max-width: 1024px) {
      .tile {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .cart-button-wrapper {
        flex: unset;
      }
    }
    @media (max-width: 767px) {
      .tile {
        flex-direction: column;
      }
      .right-col {
        flex-direction: column;
      }
      .product-options,
      .cart-button-wrapper {
        width: 100%;
      }
    }
    @media (max-width: 500px) {
      .right-col,
      .left-col {
        gap: var(--spacing-unit);
      }
      .cart-button-wrapper {
        width: unset;
        flex-grow: 1;
      }
    }
  }
}
