@import '../../styles/variables';

.decision-tree {
  max-width: 1440px;
  margin: calc(var(--spacing-unit) * 2) var(--spacing-unit);
  @include media-breakpoint-up(md) {
    margin: 50px calc(var(--spacing-unit) * 2);
  }
  @include media-breakpoint-up(lg) {
    margin: 5%;
  }
  @media (min-width: 1500px) {
    margin: 5% auto;
    padding: 0 5%;
  }

  img {
    width: 100%;
  }
  .header-block {
    cursor: pointer;
    min-height: 500px;
    border: 1px solid var(--gray);
    background-color: var(--gray);
    display: flex;
    justify-content: center;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    h4,
    h5,
    p {
      color: var(--primary);
    }
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .question-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    border: 1px solid var(--gray);
    background-color: var(--gray);
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    position: relative;
    @include media-breakpoint-down(sm) {
      > section > div {
        padding: 0;
      }
    }
    .question-back,
    .question-start-over {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 20px;
      cursor: pointer;
      margin-bottom: 2.25rem;
      p {
        margin: 0 1rem;
        text-decoration: underline;
        &:hover {
          color: var(--input-hover);
        }
      }
      svg {
        transform: rotate(180deg);
      }
    }
    p,
    h4,
    h5 {
      color: var(--black);
    }
    > section > .text-block-curaleaf > div > div > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--primary) !important;
    }
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .answer-block {
      display: flex;
      text-align: center;
      flex-direction: row;
      justify-content: space-evenly;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
  }
}
