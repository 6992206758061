@import '../../../../styles/variables';

.employee-login-modal {
  min-width: 375px;
  max-width: 90vw;
  .p-dialog-content {
    padding: var(--spacing-unit);
  }
  .p-dialog-header {
    padding: var(--spacing-unit) var(--spacing-unit) 0 var(--spacing-unit);
  }
  .p-dialog-footer {
    padding: 0 var(--spacing-unit) var(--spacing-unit) var(--spacing-unit);
    .cta-button-secondary {
      width: 100%;
      margin: 0;
    }
  }
  .email-list {
    padding-left: 17px;
    margin-bottom: 45px;
    li {
      margin-bottom: 10px;
    }
    .email {
      overflow-wrap: anywhere;
    }
    .clear-email {
      height: 20px;
      width: 20px;
      border: none;
    }
  }
  .employee-form {
    display: flex;
    .p-float-label,
    .p-inputtext {
      width: 100%;
    }
    .p-inputtext {
      border: 2px solid var(--secondary-light);
      padding: var(--spacing-unit) 12px;
      font-family: var(--font-medium);
      color: var(--primary);
      background-color: var(--secondary-light);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .p-inputtext:enabled:hover {
      border: 2px solid var(--secondary-light);
    }
    .p-inputtext:enabled:focus {
      box-shadow: var(--outline);
      border-color: var(--primary);
    }
    .p-float-label:has(input.p-filled) label {
      font-size: 12px;
      line-height: 18px;
      font-family: var(--font-medium);
      top: -14px;
      color: var(--primary);
      margin-bottom: 4px;
      left: 0;
      width: 100%;
    }
    .submit-email {
      border: 1px solid var(--primary);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 5px 10px;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.footer-disclaimer {
  order: 3;
  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    .footer-copyright {
      color: var(--primary);
    }
  }
}
